import React from "react"
import SEO from "../components/seo"
import "../styles/index.scss"

const IndexPage = () => (
  <div
    className="
  under-construction-main"
  >
    <SEO title="Home" />
    <header>
      <div className="header-content">
        <h1>Shehbaz Jafri</h1>
      </div>
    </header>
    <div className="page-center">Under Construction</div>
    <span className="bottom-right">
      <a href="/home-v1" target="_blank">
        See previous website
      </a>
    </span>
  </div>
)

export default IndexPage
